<template>
  <!-- 免费电话 -->
  <div class="tollfree">
    <se-return></se-return>
    <!-- 成人模式 -->
    <se-child v-if="!patShow"></se-child>
    <!-- 儿童模式 -->
    <se-adult v-else></se-adult>
    <!-- 提示弹框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="80%"
      :show-close="false"
      center
      top="25vh"
    >
      <span class="sz-28 fw-400 f-color6 dialog-center"
        >请摘下话机，按屏幕上的拨号键，拨打客服热线</span
      >
      <span slot="footer" class="footer fl-x-c dev-l-t">
        <div
          class="dialog-btn sz-24 fw-500 fl-x-c f-color0"
          @click="dialogVisible = false"
        >
          确 定
        </div>
      </span>
    </el-dialog>
    <div class="pattern fl-x-c fw-400" @click="toswitch">
      <img class="img2" v-if="patShow" src="@/assets/img/成人@2x.png" alt="" />
      <img class="img1" v-else src="@/assets/img/儿童@2x.png" alt="" />
      <div class="sz-20 f-color0">{{ pattern }}</div>
    </div>
    <se-footer></se-footer>
  </div>
</template>

<script>
import Return from "@/components/return";
import Child from "@/components/child";
import Adult from "@/components/adult";
import Footer from "@/components/footer/index.vue";
export default {
  components: {
    "se-return": Return,
    "se-child": Child,
    "se-footer": Footer,
    "se-adult": Adult,
  },
  data() {
    return {
      dialogVisible: true,
      pattern: "儿童模式",
      patShow: false,
    };
  },
  methods: {
    toswitch() {
      this.patShow = !this.patShow;
      if (this.patShow) {
        this.pattern = "成人模式";
      } else {
        this.pattern = "儿童模式";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tollfree {
  width: 100%;
  height: 100vh;
  background: #f6f6f6;
}
.footer {
  padding: 30px 0;
  margin: 0 33px;
  .dialog-btn {
    width: 170px;
    height: 56px;
    background: #24dadb;
    border-radius: 10px;
  }
}
.dialog-center {
  line-height: 48px;
  display: inline-block;
  padding: 45px 33px 32px;
  width: 100%;
}
.span-t {
  width: 360px;
}
.pattern {
  position: fixed;
  bottom: 16px;
  right: 20px;
  width: 146px;
  height: 50px;
  background: #ff9a38;
  border-radius: 10px;
  .img1 {
    width: 30px;
    height: 30px;
    margin-right: 3px;
  }
  .img2 {
    width: 26px;
    height: 28px;
    margin-right: 8px;
  }
}
</style>
