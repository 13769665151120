<template>
  <div class="dial">
    <!-- 拨号 -->
    <div class="adress fl-x-c">
      <img src="@/assets/img/地址@2x.png" alt="" />
      <div class="sz-28 f-color1">武昌区中北路锦绣中北二期三栋</div>
    </div>
    <div class="code fl-x-c">
      <div class="sz-24 fw-400 f-color2">设备编号：</div>
      <div class="sz-24 fw-400 f-color1">FC0271436</div>
    </div>
    <div class="keyWord">
      <div class="input">
        <el-input placeholder="" v-model="input2" class="sz-42 f-color1">
          <template slot="append">
            <div class="fl-y-c see-right" @click="Like">
              <img
                class="img2"
                v-if="dial_.seeShow"
                src="@/assets/img/眼睛@2x.png"
                alt=""
              />
              <img class="img1" v-else src="@/assets/img/隐藏@2x.png" alt="" />
              <div class="sz-20 fw-500 f-color0">{{ seeText }}</div>
            </div>
          </template>
        </el-input>
      </div>
      <div class="layout">
        <div
          class="cenBot fl-x-c sz-48 f-color1 fw-bold"
          :class="'cenBot' + index"
          v-for="(item, index) in keyList"
          :key="index"
          @click="tellphone(item)"
        >
          <img :src="item.icon" alt="" />
          <div class="fl-x-c keyNumber">{{ item }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const dial = {
  seeShow: false,
  carHeight: 100,
};
export default {
  props: {
    dial: {
      type: Object,
      default: () => dial,
    },
  },
  data() {
    return {
      input2: "",
      seeText: "隐藏号码",
      keyList: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "删除",
        "0",
        "拨号",
      ],
    };
  },
  computed: {
    dial_() {
      return Object.assign({}, dial, this.dial);
    },
  },
  methods: {
    // 显示或隐藏
    Like() {
      this.dial.seeShow = !this.dial.seeShow;
      if (this.dial.seeShow) {
        this.seeText = "显示号码";
      } else {
        this.seeText = "隐藏号码";
      }
    },
    //键盘
    tellphone(item) {
      switch (item) {
        case "拨号":
          this.$router.push({ path: "/tellphone" });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dial {
  position: relative;
  top: 260px;
}
.input {
  margin: 0 20px;
  height: 72px;
  /deep/ .el-input {
    height: 72px;
    padding: 0;
    border: none;
    border-radius: 10px;
    /deep/ .el-input__inner {
      border: none;
      border: 1px solid #24dadb;
      border-radius: 10px 0 0 10px;
      font-size: 42px;
      color: #233b3c;
    }
    /deep/ .el-input-group__append {
      background-color: #24dadb;
      border-radius: 0px 10px 10px 0px;
    }
  }
}
.see-right {
  height: 72px;
  padding: 0 23px;
  .img1 {
    width: 43px;
    height: 21px;
  }
  .img2 {
    width: 47px;
    height: 32px;
  }
}
.adress {
  padding: 0px 0 14px 0;
  img {
    width: 30px;
    height: 36px;
    margin-right: 14px;
  }
}
.keyWord {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: calc(100vh - 354px);
  background: #ffffff;
  border-radius: 36px 36px 0px 0px;
}
.layout {
  display: grid;
  padding: 60px 20px 12px;
  grid-auto-flow: row dense;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 10px;
  .cenBot9 {
    font-size: 30px !important;
  }
  .cenBot11 {
    font-size: 30px !important;
    color: #fff !important;
    .keyNumber {
      width: 150px;
      height: 80px;
      background: #24dadb;
      border-radius: 10px;
    }
  }
}
.cenBot {
  height: 80px;
}
</style>
